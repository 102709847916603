/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { PageContextProvider } from './src/contexts/page-context';
import { AuthContextProvider } from './src/contexts/auth-context';
import i18next from './i18next';

export const wrapPageElement = ({ element, props: { pageContext } }) => {
  i18next.changeLanguage(pageContext.shortLang);

  return (
    <I18nextProvider i18n={i18next}>
      <PageContextProvider pageContext={pageContext}>
        <AuthContextProvider>{element}</AuthContextProvider>
      </PageContextProvider>
    </I18nextProvider>
  );
};
